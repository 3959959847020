import React from "react";
import { Form, Col } from "react-bootstrap";

export default function SearchForm({ params, onParamChange }) {
  return (
    <div>
      <Form className="mb-4">
        <Form.Row className="align-items-end">
          <Form.Group
            as={Col}
            style={{ alignItems: "left", textAlign: "left" }}
          >
            <Form.Label style={{ fontWeight: "bold" }}>
              Job Description
            </Form.Label>
            <Form.Control
              placeholder="Filter By title, benefits, companies, expertise"
              onChange={onParamChange}
              value={params.description}
              name="description"
              type="text"
            />
          </Form.Group>
          <Form.Group
            as={Col}
            style={{ alignItems: "left", textAlign: "left" }}
          >
            <Form.Label style={{ fontWeight: "bold" }}>Location</Form.Label>
            <Form.Control
              placeholder="Filter By city, zip code or country"
              onChange={onParamChange}
              value={params.location}
              name="location"
              type="text"
            />
          </Form.Group>
          <Form.Group as={Col} xs="auto" className="ml-2">
            <Form.Check
              onChange={onParamChange}
              value={params.full_time}
              name="full_time"
              id="full-time"
              label="Only Full Time"
              type="checkbox"
              className="mb-2"
            />
          </Form.Group>
        </Form.Row>
      </Form>
    </div>
  );
}
