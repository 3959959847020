import React from "react";
import { Fade } from "react-reveal";
import Header from "../../components/header/Header";
import Profile from "../profile/Profile";
import ContactForm from "../../components/contactForm/ContactForm";

export default function ContactMe() {
  return (
    <div>
      <Header />
      <Fade bottom duration={1000} distance="20px">
        <div className="myProjects-container">
          <div>
            <Profile />
          </div>
          <div>
            <ContactForm />
          </div>
        </div>
      </Fade>
    </div>
  );
}
