import React, { Component } from "react";
import axios from "axios";
import "./contactForm.css";

export default class ContactForm extends Component {
  state = {
    name: "",
    lastname: "",
    email: "",
    message: "",
    sent: false,
    buttonText: "Send Message",
    failed: ""
  };

  // handle inputs

  handleName = (e) => {
    this.setState({
      name: e.target.value,
    });
  };

  handleLastname = (e) => {
    this.setState({
      lastname: e.target.value,
    });
  };

  handleEmail = (e) => {
    this.setState({
      email: e.target.value,
    });
  };

  handleMessage = (e) => {
    this.setState({
      message: e.target.value,
    });
  };

  // end of handle inputs

  formSubmit = (e) => {
    e.preventDefault();

    let data = {
      name: this.state.name,
      lastname: this.state.lastname,
      email: this.state.email,
      message: this.state.message,
    };

    axios
      .post("https://mailbackend.dcipher00.ml/api/forma", data)
      .then((res) => {
        this.setState(
          {
            sent: true,
          },
          this.resetForm()
        );
      })
      .catch(() => {
        console.log("Server down! \n Please mail on gaurav.mishra2050@gmail.com");
        this.setState({
          failed: "Server down! \n Please mail on gaurav.mishra2050@gmail.com",
        });
        this.resetForm()
      });
  };

  // for reseting the form data
  resetForm = () => {
    this.setState({
      name: "",
      lastname: "",
      message: "",
      email: "",
    });

    setTimeout(() => {
      this.setState({
        sent: false,
        failed: false
      });
    }, 3000);
  };

  render() {
    return (
      <div className="container" id="main-content-box">
        <h1>Drop your Contact</h1>
        <form onSubmit={this.formSubmit}>
          <div className="singleItem">
            {/* <label htmlFor="name">Name</label> */}
            <input
              type="text"
              name="name"
              className="name"
              value={this.state.name}
              onChange={this.handleName}
              placeholder="First Name"
            />
          </div>

          <div className="singleItem">
            {/* <label htmlFor="lastname">Lastname</label> */}
            <input
              type="text"
              name="lastname"
              className="lastname"
              value={this.state.lastname}
              onChange={this.handleLastname}
              placeholder="Last Name"
            />
          </div>

          <div className="singleItem">
            {/* <label htmlFor="email">Email</label> */}
            <input
              type="email"
              name="email"
              className="email"
              value={this.state.email}
              onChange={this.handleEmail}
              placeholder="Email"
              required
            />
          </div>

          <div className="textArea singleItem">
            {/* <label htmlFor="message">Message</label> */}
            <textarea
              name="message"
              value={this.state.message}
              id="messagebody"
              cols="30"
              rows="5"
              placeholder="Your message..."
              onChange={this.handleMessage}
            ></textarea>
          </div>

          <div className={this.state.sent ? "msg msgAppear" : "msg"}>
            Message has been sent successfully!!
          </div>
          <div className={this.state.failed ? "msg msgAppear failed" : "msg"}>
            {this.state.failed}
          </div>
          <div className="btn">
            <button type="submit">Submit</button>
          </div>
        </form>
      </div>
    );
  }
}
