import React, { useState } from "react";
import { Fade } from "react-reveal";
import Header from "../../components/header/Header";
import useFetchJobs from "../../components/jobCard/useFetchJobs";
import Job from "../../components/jobCard/Job";
import JobsPagination from "../../components/jobCard/JobsPagination";
import SearchForm from "../../components/jobCard/SearchForm";

export default function Jobs() {
  const [params, setParams] = useState({});
  const [page, setPage] = useState(1);
  const { jobs, loading, error, hasNextPage } = useFetchJobs(params, page);

  function handleParamChange(e) {
    const param = e.target.name;
    const value = e.target.value;
    setPage(1);
    setParams((prevParams) => {
      return { ...prevParams, [param]: value };
    });
  }

  return (
    <div>
      <Header />
      <Fade bottom duration={1000} distance="20px">
        <div style={{ paddingLeft: "10%", paddingRight: "10%" }}>
          <SearchForm params={params} onParamChange={handleParamChange} />
          <div style={{ height: "100%" }}>
            {loading && <h1>Loading Jobs...</h1>}
            {error && <h1>Error. Try Refreshing.</h1>}
            {jobs.map((job) => {
              return <Job key={job.id} job={job} />;
            })}
          </div>
          <div style={{ display: "flex", justifyContent: "flex-end" }}>
            <JobsPagination
              page={page}
              setPage={setPage}
              hasNextPage={hasNextPage}
            />
          </div>
        </div>
      </Fade>
    </div>
  );
}
