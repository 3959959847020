export const photos = [
  {
    src: "https://firebasestorage.googleapis.com/v0/b/dcipher-portfolio.appspot.com/o/bubblewash-web.gif?alt=media&token=38227fb6-5f0b-4561-950c-5270118c209c",
    width: 4,
    height: 3,
  },
  {
    src: "https://firebasestorage.googleapis.com/v0/b/dcipher-portfolio.appspot.com/o/nutri-clinic.jpeg?alt=media&token=53a573ed-706f-49cf-a1c5-ee83995df6e5",
    width: 3,
    height: 4,
  },
  {
    src: "https://firebasestorage.googleapis.com/v0/b/dcipher-portfolio.appspot.com/o/bubblewash-mob.gif?alt=media&token=029a9f91-a085-4db7-bae4-6dc805e4194c",
    width: 3,
    height: 4,
  },
  {
    src: "https://firebasestorage.googleapis.com/v0/b/dcipher-portfolio.appspot.com/o/fatchicken-mob.gif?alt=media&token=c80357fc-d0b0-4d59-b10e-b5a69a0ed3d2",
    width: 3,
    height: 4,
  },
  {
    src: "https://firebasestorage.googleapis.com/v0/b/dcipher-portfolio.appspot.com/o/project7.jpg?alt=media&token=12922c45-d31f-4f42-8efc-1a17887b89a9",
    width: 3,
    height: 4,
  },
  {
    src: "https://firebasestorage.googleapis.com/v0/b/dcipher-portfolio.appspot.com/o/fatchicken-web.gif?alt=media&token=bce1199b-a03f-49e2-8f83-67ad73b31106",
    width: 4,
    height: 3,
  },
  // {
  //   src: "https://firebasestorage.googleapis.com/v0/b/dcipher-portfolio.appspot.com/o/project5.jpg?alt=media&token=0286b8fd-c671-4ee8-995b-d5577a9609b0",
  //   width: 3,
  //   height: 4,
  // },
  // {
  //   src: "https://firebasestorage.googleapis.com/v0/b/dcipher-portfolio.appspot.com/o/project8.jpg?alt=media&token=4eb519c8-152e-4e79-9b0d-096acba33d3f",
  //   width: 4,
  //   height: 3,
  // },
  // {
  //   src: "https://firebasestorage.googleapis.com/v0/b/dcipher-portfolio.appspot.com/o/project9.jpg?alt=media&token=b02f9c7a-c2eb-4fbd-a9e9-5ef2ceb6d83e",
  //   width: 4,
  //   height: 3,
  // },
  // {
  //   src: "https://firebasestorage.googleapis.com/v0/b/dcipher-portfolio.appspot.com/o/project10.jpg?alt=media&token=b02f9c7a-c2eb-4fbd-a9e9-5ef2ceb6d83e",
  //   width: 4,
  //   height: 3,
  // },
];
