import emoji from "react-easy-emoji";

const greeting = {
  username: "Gaurav Mishra",
  title: "Hi, I'm Gaurav",
  subTitle: emoji(
    "Bengaluru based Software Developer 🚀 experienced in building Web & Mobile applications with JavaScript / Reactjs / React Native / Android / Aws / Firebase and other tools & libraries."
  ),
  resumeLink:
    "https://drive.google.com/file/d/1nCbs1hqldytxZ-SfDJKHPWETFd6SBcNY/view",
};

const socialMediaLinks = {
  github: "https://github.com/dcipher00",
  linkedin: "https://www.linkedin.com/in/dcipher/",
  gmail: "gaurav.mishra2050@gmail.com",
  instagram: "https://www.instagram.com/vicky__pedia__/",
  twitter: "https://twitter.com/dcipher00",
};

const skillsSection = {
  title: "My Tech Stack",
  subTitle: "JACK OF ALL TRADE, MASTER OF SOME",
  skills: [
    emoji(
      "⏳ Develop highly interactive Front end / User Interfaces for web and mobile applications"
    ),
    emoji("⏳ Progressive Web Applications ( PWA ) in normal and SPA"),
    emoji("⏳ Integration of third party services such as Firebase/ AWS"),
  ],

  softwareSkills: [
    {
      skillName: "JavaScript",
      fontAwesomeClassname: "fab fa-js",
    },
    {
      skillName: "reactjs",
      fontAwesomeClassname: "fab fa-react",
    },
    {
      skillName: "nodejs",
      fontAwesomeClassname: "fab fa-node",
    },
    {
      skillName: "aws",
      fontAwesomeClassname: "fab fa-aws",
    },
    {
      skillName: "firebase",
      fontAwesomeClassname: "fas fa-fire",
    },
    {
      skillName: "android",
      fontAwesomeClassname: "fab fa-android",
    },
    {
      skillName: "java",
      fontAwesomeClassname: "fab fa-java",
    },
    {
      skillName: "html-5",
      fontAwesomeClassname: "fab fa-html5",
    },
    {
      skillName: "css3",
      fontAwesomeClassname: "fab fa-css3-alt",
    },
    {
      skillName: "sass",
      fontAwesomeClassname: "fab fa-sass",
    },
    {
      skillName: "npm",
      fontAwesomeClassname: "fab fa-npm",
    },
    {
      skillName: "sql/mongodb",
      fontAwesomeClassname: "fas fa-database",
    },
    {
      skillName: "python",
      fontAwesomeClassname: "fab fa-python",
    },
    {
      skillName: "docker",
      fontAwesomeClassname: "fab fa-docker",
    },
  ],
};

// Your top 4 proficient stacks/tech experience

const techStack = {
  viewSkillBars: true, //Set it to true to show Proficiency Section
  experience: [
    {
      Stack: "Frontend Web Development", //Insert stack or technology you have experience in
      progressPercentage: "90%", //Insert relative proficiency in percentage
    },
    {
      Stack: "Mobile Development",
      progressPercentage: "80%",
    },
    {
      Stack: "Backend Development",
      progressPercentage: "60%",
    },
    {
      Stack: "Programming",
      progressPercentage: "80%",
    },
  ],
};

// Your top 2 work experiences

const workExperiences = {
  viewExperiences: true, //Set it to true to show workExperiences Section
  experience: [
    {
      role: "Senior Software Engineer",
      company: "Paytm",
      companylogo: require("./assets/images/Paytm_Logo.jpg"),
      date: "Jan 2022 – Aug 2023",
      desc:
        "Worked on React, Node, Typescript, AWS, Python, Vue, React Native, Android",
      descBullets: [
        "Worked on React Frontend of Paytm money which handles stocks and mutual fund.",
        "Worked on Insurance portal of Paytm as a Fullstack developer with Python and Vue."
        ],
    },
    {
      role: "Software Engineer",
      company: "ASM Technologies",
      companylogo: require("./assets/images/ASM-Logo.jpeg"),
      date: "October 2020 – January 2022",
      desc: "Worked on React, Node, Typescript, AWS, React native, AWS, Flask",
      descBullets: [
        "Worked on Zscaler Cloud Security Posture Management platform using React, AWS, Azure and charts.",
        "Solely created the Front-end of a FinTech product using React and Material design and also completed the integration with back-end using Rest APIs."
      ],
    },
    {
      role: "Software Developer",
      company: "Infabytes Solutions Pvt Ltd",
      companylogo: require("./assets/images/infabytes-logo.png"),
      date: "October 2018 – October 2020",
      desc: "Worked on React, Node, Typescript, AWS.",
      descBullets: [
        "Worked on Frontend of an IOT based product.",
        "Working on dashboard and data analysis system for IOT devices.",
        "Working on Machine Learning algorithm to predict faults or anomalies in the system.",
      ],
    },
    {
      role: "Web & Android Developer",
      company: "Freelance",
      companylogo: require("./assets/images/freelancer-logo.jpeg"),
      date: "Sept 2016 – Feb 2018",
      desc:
        "Working on React, Node, JavaScript, Android, ReactNative, AWS, Firebase",
      descBullets: [
        "Developed laundry web app",
        "React based chat room web application using firestore with options to create chat rooms.",
        "Developed restaurant web app",
        "React Native based grocery delivery app(Ongoing).",
        "React Native based language learning app. Interactively learn French through Hindi(Ongoing)",
      ],
    },
  ],
};

/* Your Open Source Section to View Your Github Pinned Projects
To know how to get github key look at readme.md */

const openSource = {
  githubConvertedToken: process.env.REACT_APP_GITHUB_TOKEN,
  githubUserName: "dcipher00", // Change to your github username to view your profile in Contact Section.
  showGithubProfile: "true", // Set true or false to show Contact profile using Github, defaults to false
};

// Podcast Section

const podcastSection = {
  title: emoji("Podcast 🎙️"),
  subtitle: "Should I Start a podcast..! 😅",

  // Please Provide with Your Podcast embeded Link
  podcast: [
    "https://w.soundcloud.com/player/?url=https%3A//api.soundcloud.com/tracks/880352845&color=%23ff5500&auto_play=false&hide_related=false&show_comments=true&show_user=true&show_reposts=false&show_teaser=true&visual=true",
  ],
};
const contactInfo = {
  title: emoji("Contact Me ☎️"),
  location: "Atlanta, US",
  subtitle: "Open for Freelance or to grab a coffee.\n Feel free to ping.",
  number: "+1 (470) 929 3849",
  email_address: "gaurav.mishra2050@gmail.com",
};

//Twitter Section

const twitterDetails = {
  userName: "dcipher00", //Replace "twitter" with your twitter username without @
};
export {
  greeting,
  socialMediaLinks,
  skillsSection,
  techStack,
  workExperiences,
  openSource,
  podcastSection,
  contactInfo,
  twitterDetails,
};
