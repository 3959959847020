import React, { useState } from "react";
import { Card, Badge, Button, Collapse } from "react-bootstrap";
import ReactMarkdown from "react-markdown";

export default function Job({ job }) {
  const [open, setOpen] = useState(false);

  return (
    <Card className="mb-3" style={{ borderRadius: "10px" }}>
      <Card.Body>
        <div className="d-flex justify-content-between">
          <div style={{ alignItems: "left", textAlign: "left" }}>
            <Card.Title>
              <span className="text-primary font-weight-bold">{job.title}</span>{" "}
              <br />{" "}
              <span className="text-muted font-weight-light">
                {job.company}
              </span>
            </Card.Title>
            <Card.Subtitle>
              <span className="text-dark">{"Posted On: "}</span>
              <span className="text-muted mb-2">
                {new Date(job.created_at).toLocaleDateString()}
              </span>
            </Card.Subtitle>
            <div style={{ paddingTop: "10px" }}>
              <Badge
                style={{ padding: "6px" }}
                variant="badge badge-success"
                className="mr-2"
              >
                {job.type}
              </Badge>
              <Badge style={{ padding: "6px" }} variant="badge badge-info">
                {job.location}
              </Badge>
            </div>
          </div>
          <img
            className="d-none d-md-block"
            height="50"
            alt={job.company}
            src={job.company_logo}
          />
        </div>
        <Card.Text>
          <br />
          <Button
            onClick={() => setOpen((prevOpen) => !prevOpen)}
            variant="outline-info"
          >
            {open ? "Hide Details" : "View Details"}
          </Button>
        </Card.Text>
        <Collapse in={open}>
          <div
            className="mt-4 primary"
            style={{ textAlign: "left", alignItems: "left" }}
          >
            <div style={{ wordBreak: "break-all" }}>
              <h3 className="text-dark">How To Apply</h3>
              <span className="text-dark">
                <ReactMarkdown source={job.how_to_apply} />
              </span>
            </div>
            <span className="text-dark">
              <ReactMarkdown source={job.description} />
            </span>
          </div>
        </Collapse>
      </Card.Body>
    </Card>
  );
}
