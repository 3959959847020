import React from "react";
import Header from "../components/header/Header";
import Greeting from "./greeting/Greeting";
import Skills from "./skills/Skills";
import StackProgress from "./skillProgress/skillProgress";
import WorkExperience from "./workExperience/WorkExperience";
import Projects from "./projects/Projects";
import Footer from "../components/footer/Footer";
import Podcast from "./podcast/Podcast";
import Top from "./topbutton/Top";
import Twitter from "./twitter-embed/twitter";
import Profile from "./profile/Profile";
import MyProjects from "./myProjects/myProjects";
// const Header = lazy(() => import("../components/header/Header"));
// const Greeting = lazy(() => import("./greeting/Greeting"));
// const Skills = lazy(() => import("./skills/Skills"));
// const StackProgress = lazy(() => import("./skillProgress/skillProgress"));
// const WorkExperience = lazy(() => import("./workExperience/WorkExperience"));
// const Projects = lazy(() => import("./projects/Projects"));
// const Footer = lazy(() => import("../components/footer/Footer"));
// const Podcast = lazy(() => import("./podcast/Podcast"));
// const Top = lazy(() => import("./topbutton/Top"));
// const Twitter = lazy(() => import("./twitter-embed/twitter"));
// const Profile = lazy(() => import("./profile/Profile"));

export default function Main() {
  return (
    <div>
      <Header />
      <Greeting />
      <Skills />
      <StackProgress />
      <WorkExperience />
      <MyProjects />
      <Projects />
      <Twitter />
      <Podcast />
      <Profile />
      <Footer />
      <Top />
    </div>
  );
}
