import React from "react";
import Headroom from "react-headroom";
import "./Header.css";
import { Link } from "react-router-dom";
import { greeting } from "../../portfolio";

//Home, Blogs, Project, Resume, jobs

function Header() {
  return (
    // <Headroom>
    //   <header className="header">
    //     <a href="/" className="logo">
    //       <span className="grey-color">↘</span>
    //       <span className="logo-name">{greeting.username}</span>
    //       <span className="grey-color">↗</span>
    //     </a>
    //     <input className="menu-btn" type="checkbox" id="menu-btn" />
    //     <label className="menu-icon" htmlFor="menu-btn">
    //       <span className="navicon"></span>
    //     </label>
    //     <ul className="menu">
    //       <li>
    //         <a href="#skills">Skills</a>
    //       </li>
    //       {exp === true && (
    //         <li>
    //           <a href="#experience">Experience</a>
    //         </li>
    //       )}
    //       <li>
    //         <Link to="/liveProjects">Projects</Link>
    //       </li>
    //       <li>
    //         <a href="#opensource">Open Source</a>
    //       </li>
    //       <li>
    //         <a href="#social">Social</a>
    //       </li>
    //       <li>
    //         <a href="#contact">Contact Me</a>
    //       </li>
    //     </ul>
    //   </header>
    // </Headroom>

    <Headroom>
      <header className="header">
        <a href="/" className="logo">
          <span className="grey-color">↘</span>
          <span className="logo-name">{greeting.username}</span>
          <span className="grey-color">↗</span>
        </a>
        <input className="menu-btn" type="checkbox" id="menu-btn" />
        <label className="menu-icon" htmlFor="menu-btn">
          <span className="navicon"></span>
        </label>
        <ul className="menu">
          <li>
            <Link to="/">Home</Link>
          </li>
          <li>
            <Link to="/liveProjects">Live Projects</Link>
          </li>
          {/* <li>
            <Link to="/resume">Resume</Link>
          </li> */}
          <li>
            <a
              target="_blank"
              rel="noopener noreferrer"
              href={greeting.resumeLink}
            >
              Resume
            </a>
          </li>
          <li>
            <Link to="/blogs">Blogs</Link>
          </li>
          {/* <li>
            <Link to="/jobs">Jobs</Link>
          </li> */}
          <li>
            <Link to="/contactme">Contact Me</Link>
          </li>
        </ul>
      </header>
    </Headroom>
  );
}
export default Header;
