import React, { useState, useCallback } from "react";
import "./myProjects.css";
import Gallery from "react-photo-gallery";
import Carousel, { Modal, ModalGateway } from "react-images";
import { photos } from "./photos";
import { Fade } from "react-reveal";
import Button from "../../components/button/Button";
import { Link } from "react-router-dom";

export default function MyProjects() {
  const [currentImage, setCurrentImage] = useState(0);
  const [viewerIsOpen, setViewerIsOpen] = useState(false);

  const openLightbox = useCallback((event, { photo, index }) => {
    setCurrentImage(index);
    setViewerIsOpen(true);
  }, []);

  const closeLightbox = () => {
    setCurrentImage(0);
    setViewerIsOpen(false);
  };

  return (
    <div id="my-projects">
      <Fade bottom duration={1000} distance="20px">
        <div className="myProjects-container" id="workExperience">
          <div>
            <h1 className="myProjects-heading">Projects</h1>
            <div className="myProjects-cards-div">
              <Gallery photos={photos} onClick={openLightbox} />
              <ModalGateway>
                {viewerIsOpen ? (
                  <Modal onClose={closeLightbox}>
                    <Carousel
                      currentIndex={currentImage}
                      views={photos.map((x) => ({
                        ...x,
                        srcset: x.srcSet,
                        caption: x.title,
                      }))}
                    />
                  </Modal>
                ) : null}
              </ModalGateway>
            </div>
          </div>
          <div className="button-liveProjects-div">
            <Link to="/liveProjects">
              <Button className="butt" text=" View Live Projects" />
            </Link>
          </div>
        </div>
      </Fade>
    </div>
  );
}
