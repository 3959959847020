import React from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import Main from "./containers/Main";
import LiveProjects from "./containers/liveProjects/LiveProjects";
import Blogs from "./containers/blogs/Blogs";
import Resume from "./containers/resume/Resume";
import Jobs from "./containers/jobs/Jobs";
import ContactMe from "./containers/contactme/ContactMe";

import history from "./history";

export default function Routes() {
  return (
    <Router history={history}>
      <Switch>
        <Route path="/" exact component={Main} />
        <Route path="/liveprojects" component={LiveProjects} />
        <Route path="/blogs" component={Blogs} />
        <Route path="/resume" component={Resume} />
        <Route path="/jobs" component={Jobs} />
        <Route path="/contactme" component={ContactMe} />
      </Switch>
    </Router>
  );
}
