import React, { Suspense } from "react";
import "./twitter.css";
import Loading from "../loading/Loading";
import {
  TwitterTimelineEmbed,
  TwitterFollowButton,
  TwitterDMButton,
} from "react-twitter-embed";
import { twitterDetails } from "../../portfolio";

const renderLoader = () => <Loading />;
const cantDisplayError =
  "<div class='centerContent'><h2>Can't load? Check privacy protection settings</h2></div>";

function timeOut() {
  if (document.getElementById("twitter") != null) {
    setTimeout(function () {
      if (!document.getElementById("twitter").innerHTML.includes("iframe")) {
        document.getElementById("twitter").innerHTML = cantDisplayError;
      }
    }, 10000);
  }
}
export default function Twitter() {
  if (twitterDetails.userName) {
    return (
      <Suspense fallback={renderLoader()}>
        <h1 className="social-heading" id="social">
          Social
        </h1>
        <div class="tw-main-div" id="twitter">
          <div className="centerContent">
            <TwitterTimelineEmbed
              sourceType="profile"
              noScrollbar={true}
              screenName={twitterDetails.userName}
              options={{ height: 400, width: 1200 }}
              placeholder={renderLoader()}
              autoHeight={false}
              borderColor="#fff"
              noFooter={true}
              onload={timeOut()}
            />
            <TwitterFollowButton screenName={"dcipher00"} />
            <TwitterDMButton id={187466296} />
          </div>
        </div>
      </Suspense>
    );
  } else {
    return null;
  }
}
