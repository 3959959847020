import React from "react";
import { Fade } from "react-reveal";
import Header from "../../components/header/Header";

export default function Blogs() {
  return (
    <div>
      <Header />
      <Fade bottom duration={1000} distance="20px">
        <div className="myProjects-container">
          <div>
            <h1 className="myProjects-heading">Blogs</h1>
          </div>
          <br />
          <br />
          <br />
          <br />
          <br />
          <br />
          <div>
            <h1 className="myProjects-heading">Coming Soon</h1>
          </div>
        </div>
      </Fade>
    </div>
  );
}
